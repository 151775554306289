import { z } from 'zod'
import { domainSchema } from '../domain'
import { organizationAbilitiesSchema } from './abilities'
import { organizationSettingsSchema } from './settings'
import { durationSchema } from './stats'
import { organizationSubscriptionSchema } from './subscription'

export const organizationSchema = z.object({
  organization: z.object({
    id: z.string(),
    abilities: organizationAbilitiesSchema,
    domains: z.array(domainSchema),
    name: z.string().optional(),
    score: z.number().nullable().optional(),
    duration: durationSchema,
    modules: z.array(z.enum(['CYBER_THREAT_INTELLIGENCE', 'TRAINING_AWARENESS'])),
    preferences: z.object({
      email: z.object({
        provider: z.string().nullable(),
      }),
    }),
    settings: organizationSettingsSchema,
    subscription: organizationSubscriptionSchema,
    workOs: z.object({
      organizationId: z.string().nullable(),
      isGranted: z.boolean(),
      directory: z
        .object({
          id: z.string(),
          origin: z.string(),
        })
        .nullable(),
    }),
    isWalkthroughMarkedAsCompleted: z.boolean(),
  }),
})

export type Organization = z.infer<typeof organizationSchema>
