import { fetchScenario, previewEmail, previewLanding } from '@/api/scenario'
import iziToast from 'izitoast'
import { merge } from 'lodash-es'
import { ScenarioContentAppModel } from './../../../types/scenarios'

interface FormScenario {
  categories: string[]
  lang: string
  desc: string
  name: string
  rating: number
  isAI: boolean
  id?: string
  mocking: {
    name: string
    root: string
    login: string
  }
  flags: {
    trainingButton: {
      text: string
      url: string
      show: boolean
    }
    trainingUrlCheck: {
      text: string
    }
    trainingDisplayLogo: {
      show: boolean
    }
    trainingColors: {
      text: string
      background?: string
    }
    trainingTitle: {
      text: string
    }
    trainingSubtitle: {
      text: string
    }
    emailSenderName: {
      text: string
      show: boolean
    }
    emailSenderEmail: {
      text: string
      show: boolean
    }
    emailSubject: {
      text: string
      show: boolean
    }
    emailAttachment: {
      text: string
      show: boolean
    }
    landingUrl: {
      show: boolean
      text: string
    }
  }
}

type FormScenarioContentLanding = {
  title: string
  data: string
  subdomain: string
  domain: string
  redirect: string
}

export type FormScenarioContentEmailAttachment = {
  enabled: boolean
  filename: string
  content: string
}

export type FormScenarioContentEmailAI = {
  jobTitle: string
  tone: number
  prompt: string
  isConversational: boolean
  signature: string
}

export type FormScenarioContentEmailSender = {
  domain: string
  localpart: string
  name: string
}

type FormScenarioContentEmail = {
  sender: FormScenarioContentEmailSender
  subject: string
  shouldGenerateSubjects: boolean
  data: string
  attachment: FormScenarioContentEmailAttachment
  ai: FormScenarioContentEmailAI
}

interface FormScenarioContent {
  landing: FormScenarioContentLanding
  email: FormScenarioContentEmail
}

interface ScenarioFormEditor {
  scenario: FormScenario
  content: Required<FormScenarioContent>
}

const toScenarioContentAppModel = (form: FormScenarioContent): ScenarioContentAppModel => {
  return {
    email: {
      data: form.email.data,
      sender: form.email.sender,
      subject: form.email.subject,
      attachment: form.email.attachment.enabled
        ? {
            filename: form.email.attachment.filename,
            content: form.email.attachment.content,
          }
        : null,
      ai: form.email.ai,
    },
    landing: {
      data: form.landing.data,
      domain: form.landing.domain,
      redirect: form.landing.redirect,
      subdomain: form.landing.subdomain,
      title: form.landing.title,
    },
  }
}

const defaultState = readonly({
  scenario: {
    categories: [],
    lang: 'fr',
    desc: '',
    name: '',
    rating: 3,
    mocking: {
      login: '',
      name: 'Custom',
      root: '',
    },
    isAI: false,
    flags: {
      trainingButton: {
        text: '',
        url: '',
        show: false,
      },
      trainingUrlCheck: {
        text: '',
      },
      trainingDisplayLogo: {
        show: false,
      },
      trainingColors: {
        text: '',
        background: '',
      },
      trainingTitle: {
        text: '',
      },
      trainingSubtitle: {
        text: '',
      },
      emailSenderName: {
        text: '',
        show: false,
      },
      emailSenderEmail: {
        text: '',
        show: true,
      },
      emailSubject: {
        text: '',
        show: false,
      },
      emailAttachment: {
        text: '',
        show: false,
      },
      landingUrl: {
        show: true,
        text: '',
      },
    },
  },
  content: {
    landing: {
      title: '',
      data: '',
      subdomain: '',
      domain: '',
      redirect: '',
    },
    email: {
      subject: '',
      data: '',
      sender: {
        domain: '',
        localpart: '',
        name: '',
      },
      attachment: {
        enabled: false,
        filename: '',
        content: '',
      },
      ai: {
        jobTitle: '',
        tone: 0.5, // Professional
        prompt: '',
        isConversational: true, // enable conversational by default
      },
    },
  },
})

const defaultScenarioFormState = (): ScenarioFormEditor => {
  return JSON.parse(JSON.stringify(defaultState))
}

const forms = ref(defaultScenarioFormState())

export const useScenarioForms = () => {
  const fetch = async (scenarioId: string) => {
    try {
      const [email, scenario, landing] = await Promise.all([
        previewEmail(scenarioId),
        fetchScenario(scenarioId),
        previewLanding(scenarioId),
      ])

      if (scenario) {
        merge(forms.value.scenario, scenario)

        if (email) {
          forms.value.content.email.data = email.data
          forms.value.content.email.sender = email.sender
          forms.value.content.email.subject = email.subject
          forms.value.content.email.shouldGenerateSubjects =
            !!email.aiGeneratedSubjects && email.aiGeneratedSubjects.length > 0
          forms.value.content.email.attachment = {
            enabled: !!email.attachment,
            filename: email.attachment?.filename ?? '',
            content: email.attachment?.content ?? '',
          }

          if (scenario.isAI && email.ai) {
            forms.value.content.email.ai = {
              isConversational: email.ai.isConversational,
              jobTitle: email.ai.jobTitle,
              prompt: email.ai.prompt,
              tone: email.ai.tone,
              signature: email.ai.signature ?? '',
            }
          }
        }

        if (landing) {
          forms.value.content.landing = landing
        }
      }
    } catch (error: any) {
      iziToast.error({ message: error.message })
    }
  }

  const reset = () => {
    forms.value = defaultScenarioFormState()
  }

  return {
    toScenarioContentAppModel,
    forms,
    reset,
    fetch,
  }
}
