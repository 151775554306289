import { z } from 'zod'

export const scoreSchema = z.object({
  at: z.string(),
  score: z.number(),
})

export type Score = z.infer<typeof scoreSchema>

export const scoresSchema = z.object({
  at: z.string(),
  score: z.number(),
})

export const durationSchema = z
  .object({
    average: z
      .object({
        clickedToCompromised: z.string().nullable(),
        deliveredToClicked: z.string().nullable(),
        deliveredToCompromised: z.string().nullable(),
      })
      .optional(),
    median: z
      .object({
        clickedToCompromised: z.string().nullable(),
        deliveredToClicked: z.string().nullable(),
        deliveredToCompromised: z.string().nullable(),
      })
      .optional(),
  })
  .optional()
  .nullable()

export const organizationStatsSchema = z.object({
  scores: z.array(scoresSchema),
  browsers: z.object({
    top: z
      .object({
        name: z.string(),
        version: z.string(),
        hits: z.number(),
      })
      .array(),
    low: z
      .object({
        name: z.string(),
        version: z.string(),
        hits: z.number(),
      })
      .array(),
  }),
  os: z.object({
    top: z
      .object({
        name: z.string(),
        version: z.string(),
        hits: z.number(),
      })
      .array(),
    low: z
      .object({
        name: z.string(),
        version: z.string(),
        hits: z.number(),
      })
      .array(),
  }),
  devices: z.object({
    desktop: z.number(),
    mobile: z.number(),
  }),
  employees: z
    .object({
      untrainedCount: z.number(),
      trainedCount: z.number(),
      trainedPercentage: z.number().nullable(),
    })
    .optional(),
  groups: z.object({
    low: z
      .object({
        name: z.string(),
        score: z.number(),
      })
      .array(),
    top: z
      .object({
        name: z.string(),
        score: z.number(),
      })
      .array(),
  }),
})

export type OrganizationStats = z.infer<typeof organizationStatsSchema>
