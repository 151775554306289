import { Action, Subject } from '@/types/acl'
import { RouteRecordRaw } from 'vue-router'

export const domainMonitoringRoute: RouteRecordRaw = {
  /**
   * Parent path: /domain-monitoring
   * Defined in src/modules/CyberThreatIntelligence/app/routes.ts
   */
  path: '/domain-monitoring/:domain',
  name: 'domain-monitoring-list',
  component: () => import('./DomainMonitoringPage.vue'),
  meta: {
    action: Action.READ,
    subject: Subject.CYBER_THREAT_INTELLIGENCE,
  },
}
