import { Action, Subject } from '@/types/acl'
import { RouteRecordRaw } from 'vue-router'

export const breachesRoute: RouteRecordRaw = {
  /**
   * Parent path: /breaches
   * Defined in src/modules/CyberThreatIntelligence/app/routes.ts
   */
  path: '/breaches/:domain',
  name: 'breaches-list',
  component: () => import('./BreachesPage.vue'),
  meta: {
    action: Action.READ,
    subject: Subject.CYBER_THREAT_INTELLIGENCE,
  },
}
