import { z } from 'zod'

export type EmailContentAppModel = {
  data: string
  sender: {
    domain: string
    localpart: string
    name: string
  }
  subject: string
  aiGeneratedSubjects?: string[] // Denormalized field (used by AI scenarios)
  attachment: {
    filename: string
    content: string
  } | null
  ai: AIEmailContentAppModel | null
}

export type AIEmailContentAppModel = {
  jobTitle: string
  tone: number
  prompt: string
  isConversational: boolean
  signature: string
}

export type LandingContentAppModel = {
  data: string
  domain: string
  redirect: string
  subdomain: string
  title: string
}

export type ScenarioContentAppModel = {
  email: EmailContentAppModel
  landing: LandingContentAppModel
}

export enum ScenarioSubtype {
  CREDENTIAL_HARVESTING = 'CREDENTIAL_HARVESTING',
  CLICK = 'CLICK',
}

export enum ScenarioType {
  EMAIL = 'EMAIL',
}

export type EmailContentDbModel = {
  data: string
  sender: {
    domain: string
    localpart: string
    name: string
  }
  subject: string
  attachments?: Attachment[]
}

export type LandingContentDbModel = {
  data: string
  domain: string
  redirect: string
  subdomain: string
  title: string
}

export type ScenarioContentDbModel = {
  email?: EmailContentDbModel
  landing?: LandingContentDbModel
}

interface Attachment {
  contentType: boolean
  filename: string
  data: string
}

export const scenarioSchema = z.object({
  id: z.string(),
  builtin: z.boolean(),
  isFavorite: z.boolean(),
  isTrial: z.boolean().optional(),
  isAI: z.boolean().default(false),
  categories: z.string().array(),
  deleted: z.boolean(),
  desc: z.string().nullable(),
  lang: z.string(),
  updatedAt: z.string().nullable().optional(),
  name: z.string(),
  rating: z.number(),
  flags: z.object({
    trainingButton: z.object({
      text: z.string().optional(),
      url: z.string().optional(),
      show: z.boolean(),
    }),
    trainingUrlCheck: z.object({
      text: z.string().optional(),
    }),
    trainingDisplayLogo: z.object({
      show: z.boolean(),
    }),
    trainingColors: z.object({
      text: z.string().optional(),
      background: z.string().optional(),
    }),
    trainingTitle: z.object({
      text: z.string().optional(),
    }),
    trainingSubtitle: z.object({
      text: z.string().optional(),
    }),
    emailSenderName: z.object({
      text: z.string().optional(),
      show: z.boolean(),
    }),
    emailSenderEmail: z.object({
      text: z.string().optional(),
      show: z.boolean(),
    }),
    emailSubject: z.object({
      text: z.string().optional(),
      show: z.boolean(),
    }),
    emailAttachment: z
      .object({
        text: z.string().optional(),
        show: z.boolean(),
      })
      .default({ text: '', show: false }), // to be removed once all scenarios have this field by default
    landingUrl: z.object({
      show: z.boolean(),
      text: z.string().optional(),
    }),
  }),
  thumbnail: z.string().optional(),
  subtype: z.nativeEnum(ScenarioSubtype),
  type: z.nativeEnum(ScenarioType),
  usage: z.nativeEnum(ScenarioSubtype).array().optional(),
  mocking: z
    .object({
      login: z.string(),
      name: z.string(),
      root: z.string(),
    })
    .optional(),
})

export type Scenario = z.infer<typeof scenarioSchema>
