import { can } from '@/@layouts/plugins/casl'
import { Action, Subject } from '@/types/acl'
import { RouteRecordRaw } from 'vue-router'
import { monitoredDomainRepository } from '../infra/adapters/monitored-domain.repository'
import { breachesRoute } from './views/breaches/routes'
import {
  breachesFeatureWallRoute,
  domainMonitoringFeatureWallRoute,
} from './views/featureWall/route'
import { domainMonitoringRoute } from './views/typosquattedDomains/routes'

export const cyberThreatIntelligenceRoutes = <RouteRecordRaw[]>[
  {
    name: 'breaches',
    path: '/breaches',
    beforeEnter: async (to, _from, next) => {
      const isCyberThreatIntelligenceEnabled = can(Action.READ, Subject.CYBER_THREAT_INTELLIGENCE)
      const shouldNavigateToFeatureWall =
        !isCyberThreatIntelligenceEnabled &&
        can(Action.VIEW, Subject.CYBER_THREAT_INTELLIGENCE_FEATURE_WALL)

      // Avoid infinite redirection loop
      if (to.name === 'breaches') {
        if (shouldNavigateToFeatureWall) {
          return next({ name: breachesFeatureWallRoute.name })
        }
        // @TODO: use local storage
        // Redirect to /breaches/:domain choosing the first domain available
        const {
          domains: [domain],
        } = await monitoredDomainRepository.findAll()

        if (!domain) {
          return next({ name: breachesFeatureWallRoute.name })
        }

        return next({ name: breachesRoute.name, params: { domain: domain.name } })
      }

      if (
        (isCyberThreatIntelligenceEnabled && to.name === breachesRoute.name) ||
        (shouldNavigateToFeatureWall && to.name === breachesFeatureWallRoute.name)
      ) {
        return next()
      }

      return next({ name: '404' })
    },
    // Resolve navigation sidebar selected item
    children: [breachesRoute, breachesFeatureWallRoute],
  },
  {
    name: 'domain-monitoring',
    path: '/domain-monitoring',
    beforeEnter: async (to, _from, next) => {
      const isCyberThreatIntelligenceEnabled = can(Action.READ, Subject.CYBER_THREAT_INTELLIGENCE)
      const shouldNavigateToFeatureWall =
        !isCyberThreatIntelligenceEnabled &&
        can(Action.VIEW, Subject.CYBER_THREAT_INTELLIGENCE_FEATURE_WALL)

      // Avoid infinite redirection loop
      if (to.name === 'domain-monitoring') {
        if (shouldNavigateToFeatureWall) {
          return next({ name: domainMonitoringFeatureWallRoute.name })
        }

        const {
          domains: [domain],
        } = await monitoredDomainRepository.findAll()

        if (!domain) {
          return next({ name: domainMonitoringFeatureWallRoute.name })
        }

        // Redirect to /domain-monitoring/:domain choosing the first domain available
        return next({ name: domainMonitoringRoute.name, params: { domain: domain.name } })
      }

      if (
        (isCyberThreatIntelligenceEnabled && to.name === domainMonitoringRoute.name) ||
        (shouldNavigateToFeatureWall && to.name === domainMonitoringFeatureWallRoute.name)
      ) {
        return next()
      }

      return next({ name: '404' })
    },
    // Resolve navigation sidebar selected item
    children: [domainMonitoringRoute, domainMonitoringFeatureWallRoute],
  },
]
