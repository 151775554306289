import { z } from 'zod'

export const organizationAbilitiesSchema = z.object({
  createVerifiedDomain: z.boolean(),
  enableTrainingAwareness: z.boolean(),
  viewTrainingAwarenessFeatureWall: z.boolean(),
  enableCyberThreadIntelligence: z.boolean(),
  viewCyberThreadIntelligenceFeatureWall: z.boolean(),
})

export type OrganizationAbilities = z.infer<typeof organizationAbilitiesSchema>
