import { Action, Subject } from '@/types/acl'
import { RouteRecordRaw } from 'vue-router'

export const domainMonitoringFeatureWallRoute: RouteRecordRaw = {
  path: '/domain-monitoring/overview',
  name: 'feature-wall-domain-monitoring',
  component: () => import('./DomainMonitoringFeatureWall.vue'),
  meta: {
    action: Action.VIEW,
    subject: Subject.CYBER_THREAT_INTELLIGENCE_FEATURE_WALL,
  },
}

export const breachesFeatureWallRoute: RouteRecordRaw = {
  path: '/breaches/overview',
  name: 'feature-wall-breaches',
  component: () => import('./BreachesFeatureWall.vue'),
  meta: {
    action: Action.VIEW,
    subject: Subject.CYBER_THREAT_INTELLIGENCE_FEATURE_WALL,
  },
}
