import { apiFetch } from '@/utils/api'
import { throwApiError, validateObject } from '@/utils/api-data'
import { z } from 'zod'
import { MonitoredDomainSchema } from '../../domain/entities/monitored-domain'

export type FindAllMonitoredDomainFilters = Partial<{
  status: string
}>

export const FindAllMonitoredDomainHttpResponseSchema = z.object({
  domains: z.array(MonitoredDomainSchema),
})

export type FindAllMonitoredDomainHttpResponse = z.infer<
  typeof FindAllMonitoredDomainHttpResponseSchema
>

export const monitoredDomainRepository = {
  async findAll() {
    try {
      const response = await apiFetch(`/monitored-domains`)

      return validateObject(FindAllMonitoredDomainHttpResponseSchema, response)
    } catch (error) {
      return throwApiError(error)
    }
  },
}
