import { can } from '@/@layouts/plugins/casl'
import { Action, Subject } from '@/types/acl'
import { RouteRecordRaw } from 'vue-router'

export const trainingAwarenessFeatureWallRoute: RouteRecordRaw = {
  path: '/training-awareness/overview',
  name: 'feature-wall-training-awareness',
  component: () => import('./TrainingAwarenessFeatureWall.vue'),
  meta: {
    action: Action.VIEW,
    subject: Subject.TRAINING_AWARENESS_FEATURE_WALL,
  },
  beforeEnter: (_to, _from, next) => {
    const isTrainingAwarenessEnabled = can(Action.READ, Subject.TRAINING_AWARENESS_LESSON)

    if (isTrainingAwarenessEnabled) {
      return next({ name: 'training-awareness' })
    }

    return next()
  },
}
