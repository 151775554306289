import { can } from '@/@layouts/plugins/casl'
import { Action, Subject } from '@/types/acl'
import { RouteRecordRaw } from 'vue-router'
import { trainingAwarenessFeatureWallRoute } from './views/featureWall/route'

export const trainingAwarenessRoutes: RouteRecordRaw[] = [
  {
    name: 'training-awareness',
    path: '/training-awareness',
    component: () => import('./views/listLessons/ListLessonPage.vue'),

    beforeEnter: (to, from, next) => {
      const isTrainingAwarenessEnabled = can(Action.READ, Subject.TRAINING_AWARENESS_LESSON)
      const shouldNavigateToFeatureWall =
        !isTrainingAwarenessEnabled && can(Action.VIEW, Subject.TRAINING_AWARENESS_FEATURE_WALL)

      if (shouldNavigateToFeatureWall && to.name === 'training-awareness') {
        return next({ name: trainingAwarenessFeatureWallRoute.name })
      }

      if (isTrainingAwarenessEnabled && to.name === 'training-awareness') {
        return next()
      }

      return next({ name: '404' })
    },
  },
  trainingAwarenessFeatureWallRoute,
]
