import { db, functions } from '@/services/firebase'
import { useOrganizationStore } from '@/stores/organization'
import {
  EmailContentAppModel,
  EmailContentDbModel,
  Scenario,
  ScenarioContentAppModel,
  ScenarioContentDbModel,
  scenarioSchema,
} from '@/types/scenarios'
import { apiFetch } from '@/utils/api'
import { throwApiError, validateArray, validateObject } from '@/utils/api-data'
import { collection, doc, getCountFromServer, getDoc, query, where } from '@firebase/firestore'
import { httpsCallable } from '@firebase/functions'
import type {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from 'firebase/firestore'
import { FetchError } from 'ofetch'
import { firestoreDefaultConverter } from 'vuefire'
import { z } from 'zod'

const scenarioContentEmailConverter: FirestoreDataConverter<
  EmailContentAppModel,
  EmailContentDbModel
> = {
  toFirestore: (data) => {
    return data as EmailContentDbModel
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options)
    if (!data) throw new Error('Could not find data for email content in database.')

    return {
      sender: data.sender,
      subject: data.subject,
      aiGeneratedSubjects: data.aiGeneratedSubjects,
      data: data.data,
      attachment:
        data.attachments && data.attachments.length > 0
          ? {
              content: data.attachments[0].content,
              filename: data.attachments[0].filename.replace(/\.[^./]+$/, ''),
            }
          : null,
      ai: data.ai
        ? {
            jobTitle: data.ai.agent.jobTitle,
            signature: data.ai.agent.signature ?? null,
            tone: data.ai.agent.tone,
            prompt: data.ai.agent.prompt,
            isConversational: data.ai.agent.isConversational,
          }
        : null,
    }
  },
}

export const fetchScenario = async (scenarioId: string) => {
  try {
    const { scenario } = await apiFetch<{ scenario: Scenario }>(`/read-scenario/${scenarioId}`)

    return validateObject(scenarioSchema, scenario)
  } catch (error: any) {
    return throwApiError(error)
  }
}

export const fetchScenarios = async () => {
  try {
    const { scenarios } = await apiFetch<{ scenarios: Scenario[] }>('/list-scenarios')

    return validateArray(scenarioSchema, scenarios)
  } catch (error: any) {
    return throwApiError(error)
  }
}

export const toggleFavoriteScenario = async (id: string) => {
  try {
    await apiFetch('/toggle-favorite-scenarios', {
      method: 'POST',
      body: {
        scenarioIds: [id],
      },
    })
  } catch (error: any) {
    return throwApiError(error)
  }
}

export const fetchScenarioCount = async () => {
  const orgStore = useOrganizationStore()
  const orgId = orgStore.rootOrg.organization.id

  if (!orgId) throw new Error('Missing organization id')

  const builtinRef = collection(db, 'scenarios')

  const customRef = collection(db, 'organizations', orgId, 'scenarios')

  const customQuery = query(customRef, where('deleted', '==', false))

  const builtinQuery = query(builtinRef, where('deleted', '==', false))

  const [customSnap, builtinSnap] = await Promise.all([
    getCountFromServer(customQuery),
    getCountFromServer(builtinQuery),
  ])

  return customSnap.data().count + builtinSnap.data().count
}

export const previewLanding = async (scenarioId: string, isBuiltin: boolean = false) => {
  const orgStore = useOrganizationStore()
  const orgId = orgStore.rootOrg.organization.id

  if (!orgId) throw new Error('Missing organization id')

  const scenariosRef = isBuiltin
    ? doc(db, 'scenarios', scenarioId)
    : doc(db, 'organizations', orgId, 'scenarios', scenarioId)

  const landingRef = doc(scenariosRef, 'content', 'landing')

  const docSnap = await getDoc(landingRef)

  if (docSnap.exists()) {
    return docSnap.data() as ScenarioContentDbModel['landing']
  } else {
    throw new Error('No landing found for this scenario')
  }
}

export const previewEmail = async (scenarioId: string, isBuiltin: boolean = false) => {
  const orgStore = useOrganizationStore()
  const orgId = orgStore.rootOrg.organization.id

  if (!orgId) throw new Error('Missing organization id')

  const scenariosRef = isBuiltin
    ? doc(db, 'scenarios', scenarioId)
    : doc(db, 'organizations', orgId, 'scenarios', scenarioId).withConverter({
        toFirestore(scenario: any): DocumentData {
          return scenario.doc()
        },

        fromFirestore: (snapshot: QueryDocumentSnapshot, options: SnapshotOptions) => {
          const data = firestoreDefaultConverter.fromFirestore(snapshot, options)
          if (!data) return null

          data.metadata = snapshot.metadata

          return {
            name: data.name,
            desc: data.desc,
            builtin: data.builtin,
            deleted: data.deleted,
            createdAt: data.createdAt,
            categories: data.categories,
            flags: data.flags,
            lang: data.lang,
            mocking: data.mocking,
            rating: data.rating,
            type: data.type,
            subtype: data.subtype,
            thumbnail: data.thumbnail,
            updatedAt: data.updatedAt,
          }
        },
      })

  const emailRef = doc(scenariosRef, 'content', 'email')

  const docSnap = await getDoc(
    emailRef.withConverter<EmailContentAppModel, EmailContentDbModel>(
      scenarioContentEmailConverter,
    ),
  )

  if (docSnap.exists()) {
    return docSnap.data()
  } else {
    throw new Error('No email infos found for this scenario')
  }
}

export const duplicateScenario = (id: string) =>
  httpsCallable<{ id: string }, string>(functions, 'scenarios-duplicate')({ id })

export const deleteScenario = (scenarioId: string) =>
  httpsCallable(functions, 'scenarios-delete')(scenarioId)

export const createScenario = (data: {
  scenario: Partial<Scenario>
  content: ScenarioContentAppModel
}) => httpsCallable(functions, 'scenarios-create')(data)

export const updateScenario = (data: {
  scenario: Partial<Scenario>
  content: ScenarioContentAppModel
}) => httpsCallable(functions, 'scenarios-update')(data)

export async function* sendDryRunAIScenarioPretext(
  data: {
    jobTitle: string
    prompt: string
    tone: number
    language: string
    isConversational: boolean
    signature: string | null
  },
  controller: AbortController,
) {
  try {
    const response = await apiFetch(`/dry-run-ai-scenario/send-pretext`, {
      responseType: 'stream',
      method: 'POST',
      body: data,
      signal: controller.signal,
    })

    const reader = response.getReader()

    while (true) {
      const { done, value } = await reader.read()

      if (done) {
        break
      }

      const textDecoder = new TextDecoder()
      const decodedValue = textDecoder.decode(value, { stream: true })

      yield decodedValue
    }
  } catch (error: any) {
    // user close the dialog before the request is finished
    if (error instanceof FetchError && !error.response) return

    // user closed the dialog while output is still being generated
    if (error?.name === 'AbortError') return

    if (error instanceof FetchError && error.data instanceof ReadableStream) {
      const reader = error.data.getReader()

      const { value } = await reader.read()

      const textDecoder = new TextDecoder()
      const decodedValue = textDecoder.decode(value, { stream: true })

      throw new Error(JSON.parse(decodedValue).message)
    }

    return throwApiError(error)
  }
}

export async function* handleDryRunAIScenarioReply(
  data: { message: string },
  controller: AbortController,
) {
  try {
    const response = await apiFetch(`/dry-run-ai-scenario/handle-reply`, {
      responseType: 'stream',
      method: 'POST',
      body: data,
      signal: controller.signal,
    })

    const reader = response.getReader()

    while (true) {
      const { done, value } = await reader.read()

      if (done) {
        break
      }

      const textDecoder = new TextDecoder()
      const decodedValue = textDecoder.decode(value)

      yield decodedValue
    }
  } catch (error: any) {
    // user close the dialog before the request is finished
    if (error instanceof FetchError && !error.response) return

    // user closed the dialog while output is still being generated
    if (error?.name === 'AbortError') return

    if (error instanceof FetchError && error.data instanceof ReadableStream) {
      const reader = error.data.getReader()

      const { value } = await reader.read()

      const textDecoder = new TextDecoder()
      const decodedValue = textDecoder.decode(value, { stream: true })

      throw new Error(JSON.parse(decodedValue).message)
    }

    return throwApiError(error)
  }
}

export const generateAIScenarioEmailSubject = async (data: {
  prompt: string
  tone: number
  language: string
}) => {
  try {
    const urlSearchParams = new URLSearchParams({
      prompt: data.prompt,
      tone: String(data.tone),
      language: data.language,
    })

    const response = await apiFetch(
      `/generate-ai-scenario-email-subject?${urlSearchParams.toString()}`,
    )

    return validateObject(z.object({ subject: z.string() }), response)
  } catch (error) {
    return throwApiError(error)
  }
}

export const suggestScenario = async (suggestion: string) => {
  try {
    await apiFetch('/suggest-scenario', {
      method: 'POST',
      body: {
        suggestion,
      },
    })
  } catch (error: any) {
    return throwApiError(error)
  }
}
